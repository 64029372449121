import React from 'react'

import { Box, Button, Divider, useColorMode } from "@chakra-ui/react";
import Container from '../components/container'
import ScheduleItem, { ItemLoad } from '../components/scheduleItem'
import moment from 'moment'

const ScheduleDetail = ({ data, modal, _setModal, allCompleted }) => {
    const { colorMode } = useColorMode();
    const bgColor = { light: "white", dark: "#183560" };

    function _getDosen() {
        if (!data) {
            return null
        }
        return data.course.fullname.match(/Dosen: (.*)./)[1]
    }

    function _getBerakhir() {
        if (!data) {
            return null
        }

        return moment(new Date(data.timesort * 1000)).format('dddd, Do MMMM h:mm a')
    }

    return (
        <Box bottom={!modal ? '-100vh' : 0} display='flex' justifyContent='center' transition="all ease 0.5s" position='fixed' zIndex={999} h="100vh" w="100%">
            <Box position='fixed' top={0} width='100%' height="100vh" bg="rgba(0, 0, 0, 0.4)" onClick={() => _setModal()} transition="all ease 0.5s" opacity={!modal ? 0 : 1} pointerEvents={!modal ? 'none' : 'all'} />
            <Box width={["100%", "100%", "xl", "xl"]} zIndex={99999} position='absolute' bottom={0}>

                <Box py={5} borderTopLeftRadius={10} borderTopRightRadius={10} w="100%" background={bgColor[colorMode]}>
                    <Container>
                        <Divider width="70%" border='none' bg="#E2E8F0" borderBottom='none' h='5px' rounded={100} margin="auto" />
                        {modal ? (
                            <ScheduleItem id={data.id} mk={data.mk} completed_list={allCompleted} assigment={data ? data.name : null} subject={data ? data.course.fullname : null} duedate={data ? data.timesort : null} islastday={data ? data.islastday : null} />
                        ) : (
                            <ItemLoad />
                        )}

                        {data && (
                            <React.Fragment>
                                <Box mt={6}>
                                    <Item title='Dosen' body={_getDosen()} />
                                    {/* <Item title='Kategori' body={data.course.coursecategory} /> */}
                                    <Item title='Berakhir Pada' body={_getBerakhir()} />
                                </Box>

                                <a href={data.url} rel="noreferrer" target="_blank" style={{ textDecoration: 'none' }}>
                                    <Button cursor="pointer" mt={3} _hover={{ bg: 'primaryIndie' }} bg="primary" border="none" color="white" width="100%">Kerjakan Sekarang</Button>
                                </a>
                            </React.Fragment>
                        )}
                    </Container>
                </Box>
            </Box>

        </Box>
    )
}

const Item = ({ title, body }) => (
    <Box lineHeight={1.5} mb={3}>
        <Box fontSize={14} fontWeight="bold">{title}</Box>
        <Box>{body}</Box>
    </Box>
)


export default ScheduleDetail