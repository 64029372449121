import React from 'react'
import Container from './container'
import { Box } from '@chakra-ui/react'

export default function ProgressBar({ all, done, last }) {

    // console.log('all: ' + all)
    // console.log('done: ' + done)
    // console.log('last: ' + last)

    function _getDone() {
        if (typeof done !== 'undefined') {
            return (done / all) * 100
        } else {
            return 19
        }
    }

    function _getLast() {
        if (typeof last !== 'undefined') {
            return (last / all) * 100
        } else {
            return 19
        }
    }

    return (
        <React.Fragment>
            <Container>
                <Box bg='primary' display='flex' overflow='hidden' h='5px' borderRadius={100} w="100%">
                    <Box transition='all ease 0.5s' bg='success' h='100%' w={`${_getDone()}%`}></Box>
                    <Box transition='all ease 0.5s' bg='danger' h='100%' w={`${_getLast()}%`}></Box>
                </Box>
            </Container>
        </React.Fragment>
    )
}