import React from 'react'
import { Box, Skeleton, useColorMode } from "@chakra-ui/react";
import moment from 'moment'
import 'moment/locale/id'
import { in_completed } from '../service'

const ScheduleItem = ({ id, mk, completed_list, onClick, assigment, subject, duedate, islastday }) => {
    const { colorMode } = useColorMode();
    const [accentColor, setAccentColor] = React.useState('primary')

    // console.log('item color: ', colorMode)

    function _getSubject() {
        if (!subject) {
            return subject
        }

        var mysbject = subject.match(/.+?(?= -)/)
        return mysbject.toString().toLowerCase()
    }

    function _getDueDate() {
        if (!duedate) {
            return duedate
        }
        var d = new Date(duedate * 1000)

        var days = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu']
        var months = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember']

        return days[d.getDay()] + ', ' + d.getDate() + ' ' + months[d.getMonth()]
    }

    function _getReminingTime() {
        if (!duedate) {
            return duedate
        }
        let remining = moment(new Date(duedate * 1000)).fromNow()
        return remining
    }

    function _getAssigment() {
        //  remove is due and closes
        assigment = assigment.replace('is due', '')
        assigment = assigment.replace('closes', '')

        return assigment
    }

    React.useEffect(() => {
        if (in_completed(mk, id)) {
            setAccentColor('success')
        } else {
            if (islastday) {
                setAccentColor('danger')
            } else {
                setAccentColor('primary')
            }
        }

    }, [completed_list, id, islastday, mk])

    return (
        <Box cursor='pointer' onClick={onClick || null} my={3} paddingY={3} paddingX={5} border="1px" borderColor={colorMode === 'dark' ? accentColor : '#E2E8F0'} borderLeft="4px" borderLeftColor={accentColor} borderRadius="5px">
            <Box display="flex" marginBottom={5}>
                <Box fontSize="sm" fontWeight="medium" width="60%">
                    <Skeleton height="20px" startColor="#F7FAFC" endColor="#CBD5E0" isLoaded>
                        {_getDueDate()}
                    </Skeleton>
                </Box>
                <Box width="40%" color={colorMode === 'dark' ? 'white' : '#BBC0C7'} textAlign="right">
                    <Skeleton height="15px" startColor="#F7FAFC" endColor="#CBD5E0" isLoaded>
                        <small>{_getReminingTime()}</small>
                    </Skeleton>
                </Box>
            </Box>
            <Box color={colorMode === 'dark' ? 'white' : accentColor} fontSize="xl" fontWeight="bold">
                <Skeleton dangerouslySetInnerHTML={{ __html: _getSubject(subject) }} textTransform="capitalize" startColor="#F7FAFC" endColor="#CBD5E0" isLoaded>
                </Skeleton>
            </Box>
            <Skeleton fontSize="sm" startColor="#F7FAFC" endColor="#CBD5E0" isLoaded>
                {_getAssigment()}
            </Skeleton>
        </Box>
    )
}

export const ItemLoad = () => {
    const { colorMode } = useColorMode();

    return (
        <Box my={3} paddingY={3} paddingX={5} border="1px" borderColor={colorMode === 'dark' ? 'primary' : '#E2E8F0'} borderLeft="4px" borderLeftColor={colorMode === 'dark' ? 'primary' : '#E2E8F0'} borderRadius="5px">
            <Box display="flex" marginBottom={5}>
                <Skeleton width="60%" height="20px" startColor="#F7FAFC" endColor="#CBD5E0" />
                <Skeleton width="40%" height="15px" startColor="#F7FAFC" endColor="#CBD5E0" ml={'50%'} />
            </Box>
            <Skeleton height="25px" mb={1} startColor="#F7FAFC" endColor="#CBD5E0" />
            <Skeleton height="20px" fontSize="sm" startColor="#F7FAFC" endColor="#CBD5E0" />
        </Box>
    )
}

export default ScheduleItem