import React from 'react'
import { Box, useColorMode } from "@chakra-ui/react";
import { FaRegUser } from "react-icons/fa"
import { FiCalendar } from "react-icons/fi"
import { Link } from "gatsby"

const Navigation = ({ active }) => {
    const { colorMode } = useColorMode();
    const bgColor = { light: "white", dark: "#0F2C58" };
    const activeColor = { light: "#F4F4FF", dark: "secondary" };
    const color = { light: "secondary", dark: "white" };
    const iconBg = { light: "primary", dark: "#0F2C58" };


    return (
        <Box zIndex={999} position="fixed" bottom={0} w="100%">
            <Box
                width={[
                    "100%", // base
                    "100%", // 480px upwards
                    "xl", // 768px upwards
                    "xl", // 992px upwards
                ]}
                paddingX={[
                    0,
                    0,
                    3
                ]}
                margin="auto">
                <Box w="100%" px={2} py={2} bg={bgColor[colorMode]} boxShadow="0px -3px 12px #0000001a" borderTopLeftRadius={15} borderTopRightRadius={15} d="flex">
                    <Item iconBg={iconBg[colorMode]} color={color[colorMode]} page="/" isActive={active === 1} activeColor={activeColor[colorMode]} defaultColor={bgColor[colorMode]} icon={FiCalendar} title="Schedule" />
                    <Item iconBg={iconBg[colorMode]} color={color[colorMode]} page="/profile" isActive={active === 2} activeColor={activeColor[colorMode]} defaultColor={bgColor[colorMode]} icon={FaRegUser} title="Profile" />
                </Box>
            </Box>
        </Box>
    )
}

const Item = ({ title, icon, isActive, page, activeColor, defaultColor, color, iconBg }) => (
    <Link style={{ textDecoration: 'none', width: '100%', color: "#183560" }} to={page}>
        <Box borderRadius={10} cursor="pointer" padding={2} bg={isActive ? activeColor : defaultColor} w="100%" display="flex" alignItems="center">
            <Box width="30px" display="flex" justifyContent="center" alignItems="center" color={isActive ? 'white' : color} height="30px" bg={isActive ? iconBg : defaultColor} rounded={100}><Box as={icon} /></Box>
            <Box ml={3} color={color} fontWeight="medium">{title}</Box>
        </Box>
    </Link>
)

export default Navigation