import React from "react"
import SEO from "../components/seo"
import useSWR from 'swr'

import { Box, Stack, } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react"
import Layout from "../components/layout";
import Container from '../components/container';
import ScheduleItem, { ItemLoad } from '../components/scheduleItem'
import Navigation from '../components/navigation'
import axios from 'axios'
import { isLoggedIn, clear_data, all_completed, INIT_PROGRESS_BAR, getDomain } from '../service'
import ScheduleDetail from "../components/scheduleDetail";
import Header from '../components/header'
import ProgressBar from '../components/ProgressBar'

const INIT_EL_ASSIGMENTS = () => {
  if (typeof window !== 'undefined') {
    let el = localStorage.getItem('el_assigments')
    if (el && el.length > 0) {
      return JSON.parse(el)
    } else {
      return []
    }
  } else {
    return []
  }
}

const INIT_PO_ASSIGMENTS = () => {
  if (typeof window !== 'undefined') {
    let el = localStorage.getItem('po_assigments')
    if (el && el.length > 0) {
      return JSON.parse(el)
    } else {
      return []
    }
  } else {
    return []
  }
}

const post_availability = () => {
  if (typeof window !== 'undefined') {
    return (localStorage.getItem('post_availability') === 'true')
  } else {
    return false
  }

}

const elearning_availability = () => {
  if (typeof window !== 'undefined') {
    return (localStorage.getItem('elearning_availability') === 'true')
  } else {
    return false
  }
}

const INIT_COMPLETED = () => {
  if (typeof window !== 'undefined') {
    return (JSON.parse(localStorage.getItem('all_completed')))
  } else {
    return []
  }
}

const INIT_PROGEL_DONE = () => {
  if (typeof window !== 'undefined') {
    return parseInt(localStorage.getItem('prog_el_done') || 0)
  } else {
    return 0
  }
}

const INIT_PROGPO_DONE = () => {
  if (typeof window !== 'undefined') {
    return parseInt(localStorage.getItem('prog_po_done') || 0)
  } else {
    return 0
  }
}

const INIT_PROGEL_LAST = () => {
  if (typeof window !== 'undefined') {
    return parseInt(localStorage.getItem('prog_el_last') || 0)
  } else {
    return 0
  }
}

const INIT_PROGPO_LAST = () => {
  if (typeof window !== 'undefined') {
    return parseInt(localStorage.getItem('prog_po_last') || 0)
  } else {
    return 0
  }
}


const IndexPage = () => {
  isLoggedIn()

  const toast = useToast()

  const [is_el_loading, setIsElLoading] = React.useState(true);
  const [is_po_loading, setIsPoLoading] = React.useState(true);

  const [detail, setDetail] = React.useState(null)
  const [modal, setModal] = React.useState(false)

  const [progElDone, setProgElDone] = React.useState(INIT_PROGEL_DONE)
  const [progPoDone, setProgPoDone] = React.useState(INIT_PROGPO_DONE)
  const [progElLast, setProgElLast] = React.useState(INIT_PROGEL_LAST)
  const [progPoLast, setProgPoLast] = React.useState(INIT_PROGPO_LAST)

  const [allCompleted, setAllCompleted] = React.useState(INIT_COMPLETED())

  function _setModal() {
    setModal(!modal)
  }

  function _openDetail(data, mk) {
    setDetail({ ...data, mk: mk })
    _setModal()
  }

  function _updateCompleted(data) {
    setAllCompleted(data)
  }

  const TOAST_FETCH_FAILED = (message) => {
    toast({
      description: `Gagal terhubung dengan server kampus ${message}.`,
      status: "error",
      duration: 5000,
      isClosable: true,
    })
  }

  const fetcherEL = url => {
    let d_el = JSON.parse(localStorage.getItem('d_elearning'))

    if (elearning_availability()) {
      // get uncomplete assigments
      return axios.get(url, {
        params: {
          wstoken: d_el.token,
          wsfunction: 'core_calendar_get_action_events_by_timesort',
          moodlewsrestformat: 'json'
        }
      }).then((response) => {
        // console.log(response.data)
        if (response.data.errorcode) {
          clear_data()
        } else {
          let uncomplete_asgs = []
          response.data.events.forEach(element => {
            uncomplete_asgs.push('el_' + element.id)
          });

          if (response.status === 200) {
            return axios.get(`${getDomain('el')}/webservice/rest/server.php`, {
              params: {
                wstoken: d_el.token,
                wsfunction: 'core_calendar_get_calendar_upcoming_view',
                moodlewsrestformat: 'json'
              }
            }).then((response) => {
              // console.log(response.data)
              let responseData = response.data.events.filter((e) => e.hasOwnProperty('course'))

              let all_asgs = []
              let in_last_asgs = []
              responseData.forEach(element => {
                all_asgs.push('el_' + element.id)
                if (element.islastday) {
                  in_last_asgs.push('el_' + element.id)
                }
              });

              let completed_assigments = all_asgs.filter((item) => !(uncomplete_asgs.includes(item)))
              in_last_asgs = in_last_asgs.filter((item) => !(completed_assigments.includes(item)))
              // console.log('elearning completed: ' + completed_assigments.length)
              setProgElDone(completed_assigments.length)
              setProgElLast(in_last_asgs.length)

              // set localstorage
              localStorage.setItem('prog_el_done', completed_assigments.length)
              localStorage.setItem('prog_el_last', in_last_asgs.length)

              //  get local completed assigments
              let current_completed = all_completed()
              current_completed = completed_assigments.concat(current_completed)
              current_completed = current_completed.filter((index, item) => current_completed.indexOf(index) === item)
              localStorage.setItem('all_completed', JSON.stringify(current_completed))
              setAllCompleted(current_completed)

              localStorage.setItem('el_assigments', JSON.stringify(responseData))
              return responseData
            })
          }
        }
      }).catch(() => {
        console.log('masuk sini dong')
        TOAST_FETCH_FAILED('(E-Learning)')
        setIsElLoading(false)
      })
    } else {
      return []
    }
  }

  const fetcherPO = url => {
    let d_po = JSON.parse(localStorage.getItem('d_post'))

    if (post_availability()) {
      // get uncomplete assigments
      return axios.get(url, {
        params: {
          wstoken: d_po.token,
          wsfunction: 'core_calendar_get_action_events_by_timesort',
          moodlewsrestformat: 'json'
        }
      }).then((response) => {
        // console.log(response.data)

        if (response.data.errorcode) {
          clear_data()
        } else {
          if (response.status === 200) {
            let uncomplete_asgs = []
            response.data.events.forEach(element => {
              uncomplete_asgs.push('po_' + element.id)
            });

            // get alll assigments
            return axios.get(`${getDomain('po')}/webservice/rest/server.php`, {
              params: {
                wstoken: d_po.token,
                wsfunction: 'core_calendar_get_calendar_upcoming_view',
                moodlewsrestformat: 'json'
              }
            }).then((response) => {
              // console.log(response.data)
              let responseData = response.data.events.filter((e) => e.hasOwnProperty('course'))

              let all_asgs = []
              let in_last_asgs = []
              responseData.forEach(element => {
                all_asgs.push('po_' + element.id)
                if (element.islastday) {
                  in_last_asgs.push('po_' + element.id)
                }
              });

              let completed_assigments = all_asgs.filter((item) => !(uncomplete_asgs.includes(item)))
              in_last_asgs = in_last_asgs.filter((item) => !(completed_assigments.includes(item)))

              // console.log('post completed: ' + completed_assigments.length)
              setProgPoDone(completed_assigments.length)
              setProgPoLast(in_last_asgs.length)

              // set localstorage
              localStorage.setItem('prog_po_done', completed_assigments.length)
              localStorage.setItem('prog_po_last', in_last_asgs.length)

              //  get local completed assigments
              let current_completed = all_completed()
              current_completed = completed_assigments.concat(current_completed)
              current_completed = current_completed.filter((index, item) => current_completed.indexOf(index) === item)
              localStorage.setItem('all_completed', JSON.stringify(current_completed))
              setAllCompleted(current_completed)

              localStorage.setItem('po_assigments', JSON.stringify(responseData))
              return responseData
            })
          }
        }
      }).catch((error) => {

        console.log(error.response)
        TOAST_FETCH_FAILED('(POST)')
        setIsPoLoading(false)
      })
    } else {
      return []
    }
  }

  const { data: elearning } = useSWR(`${getDomain('el')}/webservice/rest/server.php`, fetcherEL, { fallbackData: INIT_EL_ASSIGMENTS(), onSuccess: () => setIsElLoading(false) })
  const { data: post } = useSWR(`${getDomain('po')}/webservice/rest/server.php`, fetcherPO, { fallbackData: INIT_PO_ASSIGMENTS(), onSuccess: () => setIsPoLoading(false) })

  React.useEffect(() => {
    // get e learining
    console.log('render home')
  }, [])

  return (
    <Layout>
      <SEO title="Mercu" />
      <Navigation active={1} />

      <ScheduleDetail allCompleted={allCompleted} _updateCompleted={_updateCompleted} modal={modal} _setModal={_setModal} data={detail} />

      <Header title="Schedule" mb={INIT_PROGRESS_BAR() ? 0 : 5} />

      <Stack mt="5" spacing="5">
        {((elearning.length > 0) || (post.length > 0)) === INIT_PROGRESS_BAR() && (
          <ProgressBar all={elearning.length + post.length} done={progElDone + progPoDone} last={progElLast + progPoLast} />
        )}

        {elearning_availability() && (
          <Box>
            <Container>
              <Box fontWeight="bold">E Learning</Box>
              <Box>
                {elearning.length > 0 || !is_el_loading ? (
                  <React.Fragment>
                    {elearning.map((data, i) => (
                      <React.Fragment key={i}>
                        {data.eventtype !== 'open' && (
                          <ScheduleItem id={data.id} mk='el' completed_list={allCompleted} onClick={() => _openDetail(data, 'el')} assigment={data.name} subject={data.course.fullname} duedate={data.timesort} islastday={data.islastday} />
                        )}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ) : (
                  <section>
                    <ItemLoad />
                  </section>
                )}
              </Box>
            </Container>
          </Box>
        )}

        {post_availability() && (
          <Box>
            <Container>
              <Box fontWeight="bold">POST</Box>
              <Box>
                {post.length > 0 || !is_po_loading ? (
                  <React.Fragment>
                    {post.map((data, i) => (
                      <React.Fragment key={i}>
                        {data.eventtype !== 'open' && (
                          <ScheduleItem id={data.id} mk='po' completed_list={allCompleted} onClick={() => _openDetail(data, 'po')} assigment={data.name} subject={data.course.fullname} duedate={data.timesort} islastday={data.islastday} />
                        )}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ) : (
                  <section>
                    <ItemLoad />
                  </section>
                )}
              </Box>
            </Container>
          </Box>
        )}
      </Stack>
    </Layout>
  )
}

export default IndexPage
